import React from "react";
import style from "./SubHeader.module.css";

function SubHeader(props) {
  return (
    <div className={style.subHead}>
      <p className={style.title}>{props.title}</p>
      <p className={style.subTitle}>
        {props.subTitle}
        {props.link_text1 && (
          <span className={style.link}>{props.link_text1}</span>
        )}
        {props.link_text2 && (
          <>
            {" "}
            {/*props.t("ou")}{" "*/}
            {props.outext}{" "}
            <span className={style.link}>{props.link_text2}</span>{" "}
          </>
        )}
        {props.rest_subTitle ? props.rest_subTitle : null}
      </p>
    </div>
  );
}

export default SubHeader;
