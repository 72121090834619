import React from "react";
import style from "./style.module.css";
import useTranslation from "next-translate/useTranslation";
import formatUrl from "utils/FormatUrl";
import Image from "next/image";

function index(props) {
  const url = `${props.button_link}`;
  const { lang } = useTranslation("");
  return (
    <div className={style.container}>
      <div className={style.image}>
        <img src={props.image}  alt={""} />
      </div>
      <div className={style.textCon}>
        <span className={style.title}>{props.title}</span>
        <span className={style.text}>
          <span className={style.textColor}>{props.text_color}</span>
          {props.text}
        </span>
      </div>
    </div>
  );
}

export default index;
