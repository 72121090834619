import React from "react";
import style from "./style.module.css";

function index(props) {
  return (
    <div className={style.itemsContainer}>
      <div className={style.items}>
        {props.title && <span className={style.title}>{props.title}</span>}
        {props.texte1 && <span className={style.texte}>{props.texte1}</span>}
        {props.texte2 && <span className={style.texte}>{props.texte2}</span>}
      </div>
    </div>
  );
}

export default index;
