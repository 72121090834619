import useTranslation from "next-translate/useTranslation";
import React from "react";
import formatUrl from "utils/FormatUrl";
import style from "./style.module.css";
function index(props) {
  const { t, lang } = useTranslation("");
  return (
    <div className={style.btnsContainer}>
      <div className={style.items}>
        <p className={style.title}>{props.titre}</p>
        <div className={style.btns}>
          <div className={style.btndiv}>
            <a href={formatUrl(props.link1, lang)}>
              <button className={style.btn}>{props.btn1}</button>
            </a>
          </div>
          <div className={style.btndiv}>
            <a
              href={formatUrl(props.link2, lang)}
              rel={
                props.link2?.includes("list") ||
                props.link2?.includes("map") ||
                props.link2?.includes("?") ||
                props.link2?.includes("conditions") ||
                props.link2?.includes("contact")
                  ? "noindex, nofollow, noreferrer"
                  : "dofollow"
              }
            >
              <button className={style.btn}>{props.btn2}</button>
            </a>
          </div>
          <div className={style.btndiv}>
            <a
              href={formatUrl(props.link3, lang)}
              rel={
                props.link3?.includes("list") ||
                props.link3?.includes("map") ||
                props.link3?.includes("?") ||
                props.link3?.includes("conditions") ||
                props.link3?.includes("contact")
                  ? "noindex, nofollow, noreferrer"
                  : "dofollow"
              }
            >
              <button className={style.btn}>{props.btn3}</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default index;
