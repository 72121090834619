import React from "react";
import style from "./AideCards.module.css";
import Image from "next/image";

function AideCards(props) {
  return (
    <div className={`${style.card} ${props.trouvezagence ? style.card2 : ""}`}>
      <div
        className={style.img}
        style={{
          width: props.width,
          height: props.height,
          position: "relative",
        }}
      >
        {/* <img
          src={props.backgroundUrl}
          alt={props.title}
          width={props.width}
          height={props.height}
        /> */}
        <Image
          unoptimized
          src={props.backgroundUrl}
          alt={props.title}
          style={{
            objectFit: "cover",
            objectPosition: "center",
            width: "100%",
          }}
          fill
        />
      </div>
      <div className={style.texts}>
        <div className={style.titleDiv}>
          <p className={style.title}>{props.title}</p>
        </div>
        <div className={style.descDiv}>
          <p className={style.description}>{props.content}</p>
        </div>
      </div>
    </div>
  );
}

export default AideCards;
