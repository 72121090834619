import React from "react";
import style from "./ImageTitleSubTitleBtnFlex.module.css";
import Image from "next/image";
import formatUrl from "utils/FormatUrl";

function index(props) {
  return (
    <div
      className={style.container}
      style={{ background: props.bgWhite ? "#fff" : "" }}
    >
      <div
        className={`${style.frameGParent} ${
          props.isReverse ? style.frameGParentRev : null
        } `}
      >
        <div
          className={style.imgDiv}
          style={{ alignItems: props.isReverse ? "flex-start" : "" }}
        >
          <Image
            unoptimized
            className={style.backgroundImage}
            src={props.image}
            alt={props.title}
            fill
          />
        </div>
        <div className={style.frameParent}>
          <div className={style.contentCon}>
            <span className={style.title}>{props.title}</span>
            <span className={style.subtitle}>{props.subtitle}</span>
          </div>
          <div className={style.btnCon}>
            <a
              href={formatUrl(props.button_link, props.lang)}
              className={style.btn}
            >
              {props.buttonText}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default index;
